import React from "react"
import PropTypes from "prop-types"
import * as styles from "./board-of-directors.module.scss"
import Agenda from "./agenda"
import Minutes from "./minutes"

function FileLinks(props) {
  let fileLinksContainerStyle = styles.docs_container
  if (props.agenda && (props.minutesApproved || props.minutesDraft)) {
    fileLinksContainerStyle = styles.docs_container_all
  }

  if (!props.agenda && !props.minutesDraft && !props.minutesApproved) {
    return <div className={fileLinksContainerStyle}>
      <h3>Coming Soon...</h3>
    </div>
  } else {
    return (
      <div className={fileLinksContainerStyle}>
        <Agenda agenda={props.agenda} />
        <Minutes
          minutesDraft={props.minutesDraft}
          minutesApproved={props.minutesApproved}
        />
      </div>
    )
  }
}

FileLinks.propTypes = {
  agenda: PropTypes.string,
  minutesDraft: PropTypes.string,
  minutesApproved: PropTypes.string,
}

export default FileLinks
