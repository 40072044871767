import React from "react"
import PropTypes from "prop-types"

function DocumentLink(props) {
  if (props.link) {
    return (
      <li>
        <a href={props.link} target="_blank" rel="noreferrer">
          <p tabIndex={0}>{props.text}</p>
        </a>
      </li>
    )
  } else {
    return <></>
  }
}

DocumentLink.propTypes = {
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default DocumentLink
