import React from "react"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import * as styles from "./partners.module.scss"

function EasternMichiganUniversity(props) {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              emu_hero_image {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div>
      <h2 id={props.id}>Eastern Michigan University</h2>
      <div
        className={`${styles.image_and_text_container} ${styles.left_image}`}
      >
        <div className={styles.image_container}>
          <GatsbyImage
            className={styles.image_container_image}
            alt={data.emu_hero_image.alt}
            image={data.emu_hero_image.gatsbyImageData}
          />
        </div>
        <div className={styles.text_container}>
          <p>
            We have been authorized by Eastern Michigan University since our
            opening in 2013. Our current charter extends through 2025.
          </p>
          <p>
            <strong>Contact Information</strong>
            <br />
            Charter Schools Office <br />
            310 Porter Building, Ypsilanti, MI 48197
            <br />
            Phone: 734.487.2086 <br />
            Fax: 734.487.5310 <br />
            <a target="_blank" rel="noreferrer" href="mailto:charter_schools@emich.edu">
              <p tabindex={0} className="inline">charter_schools@emich.edu</p>
            </a>
          </p>
        </div>
      </div>
    </div>
  )
}

export default EasternMichiganUniversity
