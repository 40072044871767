import React from "react"
import * as styles from "./about-us.module.scss"
import AboutUsContentSwapper from "./about-us-content-swapper"

function AboutUs({currentSwapperItem, swapperVisible}) {
  return (
    <div className={styles.container}>
      <span />
      <AboutUsContentSwapper
        current={currentSwapperItem}
        visible={swapperVisible}
      />
    </div>
  )
}

export default AboutUs
