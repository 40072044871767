import React from "react"
import * as styles from "./partners.module.scss"
import EasternMichiganUniversity from "./eastern-michigan-university"
import AFTMichigan from "./aft-michigan"
import MentorSchools from "./mentor-schools"
import CommunityPartners from "./community-partners"
import Support from "./support"
import { navItems } from "../about-us-nav-bar"
import { getContentSwapAnimation } from "../../content-swapper-utils"

function Partners(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)
  return (
    <div className={`${styles.partners} ${containerAnimation}`}>
      <section>
        <h1>Partners</h1>
        <hr />
      </section>
      <section className={styles.section_emu}>
        <EasternMichiganUniversity
          id={navItems.easternMichiganUni.link.substring(1)}
        />
      </section>
      <section
        className={`${styles.section_aft} ${styles.section_half_circle}`}
      >
        <AFTMichigan id={navItems.aftMi.link.substring(1)} />
      </section>
      <section
        className={`${styles.section_half_circle} ${styles.section_mentor_schools}`}
      >
        <MentorSchools id={navItems.mentorSchools.link.substring(1)} />
      </section>
      <section
        className={`${styles.section_boxes} ${styles.section_community_partners}`}
      >
        <CommunityPartners id={navItems.communityPartners.link.substring(1)} />
      </section>
      <section className={`${styles.section_support}`}>
        <Support id={navItems.support.link.substring(1)} />
      </section>
    </div>
  )
}

export default Partners
