import React, { useState } from "react"
import Layout from "../components/layout"
import SEO from "../components/seo"
import AboutUs from "../components/about-us/"
import { headerNames } from "../components/navigation/header"
import { navItems } from "../components/about-us/about-us-nav-bar"

function AboutUsPage() {
  const [currentSwapperItem, setCurrentSwapperItem] = useState(
    navItems.missionAndCoreIdeology.name
  )
  const [swapperVisible, setSwapperVisible] = useState(true)

  return (
    <Layout
      activeHeader={headerNames.aboutUs}
      setCurrentSwapperItem={setCurrentSwapperItem}
      setSwapperVisible={setSwapperVisible}
    >
      <SEO title="About Us" />
      <AboutUs
        currentSwapperItem={currentSwapperItem}
        swapperVisible={swapperVisible}
      />
    </Layout>
  )
}

export default AboutUsPage
