import React from "react"
import TransparencyLinkList from "./transparency-link"
import * as styles from "./transparency.module.scss"
import { graphql, useStaticQuery } from "gatsby"
import { getContentSwapAnimation } from "../../content-swapper-utils"

function Transparency(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsTransparencyLinks {
        edges {
          node {
            data {
              links {
                link1 {
                  url
                }
                title {
                  text
                }
                no_links_text {
                  text
                }
                link4 {
                  url
                }
                link3 {
                  url
                }
                link2 {
                  url
                }
                text1 {
                  text
                }
                text2 {
                  text
                }
                text3 {
                  text
                }
                text4 {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsTransparencyLinks.edges[0].node.data
  return (
    <div className={`${containerAnimation} ${styles.transparency}`}>
      <section className={styles.transparency_intro_section}>
        <div className={styles.transparency_intro}>
          <h1>Budget &amp; Salary/Compensation Transparency</h1>
          <div>
            <p>
              Please follow <a target="_blank" href="https://www.mischooldata.org">this link</a> to
              visit the MI School Data website.
            </p>
          </div>
        </div>
      </section>
      <section className={styles.section_boxes}>
        <div className={styles.transparency_links}>
          {data.links.map((item) => (
            <TransparencyLinkList links={item} />
          ))}
        </div>
      </section>
    </div>
  )
}

export default Transparency
