import React from "react"
import * as styles from "./partners.module.scss"
import ImageLinkOut from "./image-link-out"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function CommunityPartners(props) {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsCommunityPartners {
        edges {
          node {
            data {
              community_partners_group {
                image {
                  alt
                  gatsbyImageData(width: 150, layout: FIXED)
                }
                link {
                  url
                }
                title {
                  text
                }
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div>
      <h2 id={props.id}>Community Partners</h2>
      <div className={styles.partner_links_container}>
        {query.allPrismicBoggsCommunityPartners.edges[0].node.data.community_partners_group.map(
          (obj, index) => {
            const { image, link, title } = obj
            if (image.gatsbyImageData) {
              return (
                <ImageLinkOut
                  key={index}
                  alt={image.alt}
                  image={image.gatsbyImageData}
                  link={link.url}
                  text={title.text}
                />
              )
            } else {
              return (
                <div className={styles.link_out_no_image}>
                  <h3>{title.text}</h3>
                </div>
              )
            }
          }
        )}
      </div>
    </div>
  )
}

CommunityPartners.propTypes = {
  id: PropTypes.string.isRequired,
}

export default CommunityPartners
