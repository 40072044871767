// extracted by mini-css-extract-plugin
export var container_animation = "transparency-module--container_animation--TyJ0f";
export var container_animation_hide = "transparency-module--container_animation_hide--NbaHo";
export var container_animation_show = "transparency-module--container_animation_show--IrFwt";
export var content_swapper_container = "transparency-module--content_swapper_container--tq-SU";
export var content_swapper_container_show = "transparency-module--content_swapper_container_show--4yAxQ";
export var drop_down_hide = "transparency-module--drop_down_hide--TCUfu";
export var drop_down_show = "transparency-module--drop_down_show--jDzhQ";
export var icon = "transparency-module--icon--DSvjK";
export var links_title_and_icon = "transparency-module--links_title_and_icon--OhMa-";
export var section_bottom_triangle = "transparency-module--section_bottom_triangle--r6quQ";
export var section_boxes = "transparency-module--section_boxes--BslV4";
export var section_half_circle = "transparency-module--section_half_circle--q3cw+";
export var transparency = "transparency-module--transparency--UDuGR";
export var transparency_intro = "transparency-module--transparency_intro--y9KE5";
export var transparency_intro_section = "transparency-module--transparency_intro_section--iWAwe";
export var transparency_links = "transparency-module--transparency_links--YKNfW";