import React from "react"
import PropTypes from "prop-types"
import * as styles from "./board-of-directors.module.scss"
import FileLinks from "./file-links"

function BoardMeeting({
  type,
  date,
  time,
  agenda,
  minutesDraft,
  minutesApproved,
}) {
  return (
    <div className={styles.board_meeting_item}>
      <h2>{type} Meeting</h2>
      <p>
        {date} {time}
      </p>
      <FileLinks
        agenda={agenda}
        minutesDraft={minutesDraft}
        minutesApproved={minutesApproved}
      />
    </div>
  )
}

BoardMeeting.propTypes = {
  type: PropTypes.string.isRequired,
  date: PropTypes.string.isRequired,
  agenda: PropTypes.string,
  minutesDraft: PropTypes.string,
  minutesApproved: PropTypes.string,
}

export default BoardMeeting

export const ORG = "Organizational"
export const REG = "Regular"
export const BUDGET = "Budget"
