import React from "react"
import PropTypes from "prop-types"
import * as styles from "./board-of-directors.module.scss"

function Minutes(props) {
  if (props.minutesDraft && props.minutesApproved) {
    return (
      <div className={styles.agenda_and_minutes_container}>
        <h3>Minutes</h3>
        <div className={styles.minutes_both_subcontainer}>
          <a href={props.minutesDraft} rel="noreferrer" target="_blank"><p tabIndex={0}>Draft</p></a>
          <a href={props.minutesApproved} rel="noreferrer" target="_blank"><p tabIndex={0}>Approved</p></a>
        </div>
      </div>
    )
  } else if (props.minutesDraft) {
    return (
      <div className={styles.agenda_and_minutes_container}>
        <h3>Minutes</h3>
        <a href={props.minutesDraft} rel="noreferrer" target="_blank"><p tabIndex={0}>Draft</p></a>
      </div>
    )
  } else if (props.minutesApproved) {
    return (
      <div className={styles.agenda_and_minutes_container}>
        <h3>Minutes</h3>
        <a href={props.minutesApproved} rel="noreferrer" target="_blank"><p tabIndex={0}>Approved</p></a>
      </div>
    )
  } else {
    return <></>
  }
}

Minutes.propTypes = {
  minutesDraft: PropTypes.string,
  minutesApproved: PropTypes.string,
}

export default Minutes
