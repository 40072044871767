// extracted by mini-css-extract-plugin
export var container_animation = "origins-module--container_animation--5p--s";
export var container_animation_hide = "origins-module--container_animation_hide--0oSSg";
export var container_animation_show = "origins-module--container_animation_show--01qKG";
export var content_swapper_container = "origins-module--content_swapper_container--5KNfm";
export var content_swapper_container_show = "origins-module--content_swapper_container_show--AD3zQ";
export var drop_down_hide = "origins-module--drop_down_hide--Od237";
export var drop_down_show = "origins-module--drop_down_show--u-T8a";
export var general_image_container = "origins-module--general_image_container--QxkPi";
export var image = "origins-module--image--YjEvy";
export var image_1_container = "origins-module--image_1_container--cidIz";
export var image_2_container = "origins-module--image_2_container--3sI4y";
export var image_3_container = "origins-module--image_3_container--sna-e";
export var origins = "origins-module--origins--7565z";