// extracted by mini-css-extract-plugin
export var bio_container = "grace-and-jimmy-module--bio_container--+85+a";
export var container_animation = "grace-and-jimmy-module--container_animation--N1DLa";
export var container_animation_hide = "grace-and-jimmy-module--container_animation_hide--pPnmZ";
export var container_animation_show = "grace-and-jimmy-module--container_animation_show--OEjgo";
export var content_swapper_container = "grace-and-jimmy-module--content_swapper_container--SBQyy";
export var content_swapper_container_show = "grace-and-jimmy-module--content_swapper_container_show--jNsXG";
export var drop_down_hide = "grace-and-jimmy-module--drop_down_hide--Vpo00";
export var drop_down_show = "grace-and-jimmy-module--drop_down_show--ocH-B";
export var grace_and_jimmy = "grace-and-jimmy-module--grace_and_jimmy--mqKVq";
export var head_shot = "grace-and-jimmy-module--head_shot--6EifU";
export var head_shot_container = "grace-and-jimmy-module--head_shot_container--buJth";
export var image = "grace-and-jimmy-module--image--bDT8V";
export var intro_image = "grace-and-jimmy-module--intro_image--7fn7Z";
export var jimmy_bio = "grace-and-jimmy-module--jimmy_bio--VrCx0";
export var jimmy_image = "grace-and-jimmy-module--jimmy_image--9jcW9";
export var outro_image = "grace-and-jimmy-module--outro_image--VLLeK";
export var section_bottom_triangle = "grace-and-jimmy-module--section_bottom_triangle--CaAYE";
export var section_boxes = "grace-and-jimmy-module--section_boxes--B23vB";
export var section_half_circle = "grace-and-jimmy-module--section_half_circle--b2giW";
export var section_outro = "grace-and-jimmy-module--section_outro--oqO+g";