import React from "react"
import PropTypes from "prop-types"
import * as styles from "./board-of-directors.module.scss"
import BoardMeeting from "./board-meeting"
import OfficialDocuments from "./official-documents"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import { useStaticQuery, graphql } from "gatsby"
import { getFormattedDate } from "../../utils"

function BoardOfDirectors(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsBoardMeeting(sort: { fields: data___date, order: DESC }) {
        edges {
          node {
            data {
              agenda {
                url
              }
              date
              meeting_type
              minutes_draft {
                url
              }
              minutes_published {
                url
              }
              time {
                text
              }
            }
          }
        }
      }
    }
  `)

  return (
    <div className={`${containerAnimation} ${styles.board_of_directors}`}>
      <section className={styles.intro_section}>
        <h1>Board of Directors</h1>
        <p>
          Principal Office:
          <br />
          7600 Goethe Street, Detroit, MI 48214
          <br />
          Official Hours: 9am - 4pm, Monday through Friday
          <br />
          Phone: (313) 923-2301
        </p>
        <p>
          All meetings of the Board of Directors are held at the James &amp;
          Grace Lee Boggs School beginning at 6:30pm unless noted otherwise
          below or as posted on the school door prior to the day of the
          scheduled meeting.
        </p>
        <OfficialDocuments />
      </section>
      <section
        className={`${styles.section_bottom_triangle} ${styles.board_section}`}
      >
        <h1>Meeting Dates &amp; Information</h1>
        <ul className={styles.board_meetings}>
          {query.allPrismicBoggsBoardMeeting.edges.map((obj, index) => {
            const {
              agenda,
              date,
              meeting_type,
              minutes_draft,
              minutes_published,
              time,
            } = obj.node.data

            const formattedDate = getFormattedDate(date)
            return (
              <li key={index}>
                <BoardMeeting
                  type={meeting_type}
                  date={formattedDate}
                  time={time.text}
                  agenda={agenda.url}
                  minutesDraft={minutes_draft.url}
                  minutesApproved={minutes_published.url}
                />
              </li>
            )
          })}
        </ul>
      </section>
      <section className={styles.outro_section}>
        <h1>Board Procedures</h1>
        <div className={styles.disclaimer_items}>
          <div>
            <p>
              Minutes of all meetings of the Board of Directors are available
              for public review at the office of the Board of Directors no more
              than five business days following the meeting at which the minutes
              were approved.
            </p>
            <br />
            <p>
              Notice of any special meeting of the Board of Directors is posted
              in accordance with the Michigan Open Meetings Act at the principal
              office of the Board of Directors no less than eighteen hours prior
              to the special meeting.
            </p>
            <br />
            <p>
              Persons needing special assistance to attend a meeting of the
              Board of Directors are asked to contact the Board of Directors
              Office at (313) 923-2301 no later than 4 p.m. three business days
              prior to the meeting the person wishes to attend.
            </p>
          </div>
          <div>
            <div className={styles.inline}>
              <p>
                Draft minutes will be posted within eight business days of the
                meeting at which they were recorded. Approved minutes will be
                posted within five business days of the meeting at which they
                were recorded. This timeline is in accordance with the Michigan
                Open Meetings Act. Minutes no longer shown on this page are
                available upon request. Please email{" "}
              </p>
              <a href="mailto:boggsschool@boggsschool.org">
                <p tabIndex={0}>boggsschool@boggsschool.org</p>
              </a>
              {"."}
            </div>
            <br />
            <p>
              The James and Grace Lee Boggs School, as an equal opportunity
              employer, complies with all applicable federal and state laws
              regarding nondiscrimination. The Boggs School is committed to a
              policy of equal opportunity for all persons and does not
              discriminate on the basis of race, color, religion, national
              origin or citizenship status, age, marital status, sex, gender
              identity or expression, sexual orientation, disability, height,
              weight, pregnancy, or veteran status in employment.
            </p>
          </div>
        </div>
      </section>
    </div>
  )
}

export default BoardOfDirectors

BoardOfDirectors.propTypes = {
  visible: PropTypes.bool.isRequired,
}
