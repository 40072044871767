import React from "react"
import PropTypes from "prop-types"
import * as styles from "./place-based-education.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getContentSwapAnimation } from "../../content-swapper-utils"

function PlaceBasedEducation(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              place_based_education {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div className={`${containerAnimation} ${styles.place_based_education}`}>
      <section>
        <h1>Place-Based Education</h1>
        <div className={styles.p_b_e_copy_1}>
          <p>
            To fulfill our goals, we have selected to implement a Place-Based
            Education (PBE) model. To this end, we work in close collaboration
            with the{" "}
            <a href="http://semiscoalition.org/mission/" rel="noreferrer" target="_blank">
              <p tabindex={0}>
                Southeast Michigan Stewardship Coalition (SEMIS)
              </p>
            </a>
            , an organization under both Eastern Michigan University and{" "}
            <a href="http://semiscoalition.org/great-lakes-stewardship-initiative/" rel="noreferrer" target="_blank">
              <p tabIndex={0}>the Great Lakes Stewardship Initiative</p>
            </a>
            , which is committed to school-community partnerships to develop
            students as citizen-stewards of healthy ecological-social systems.
          </p>
        </div>
        <div className={styles.p_b_e_hero_image_container}>
          <GatsbyImage
            className={styles.p_b_e_hero_image}
            alt={data.place_based_education.alt}
            image={data.place_based_education.gatsbyImageData}
          />
        </div>
        <div className={styles.p_b_e_copy_2}>
          <p>
            PBE is a nationally renowned and research-based model that has
            facilitated high student achievement in areas like Oakland,
            Louisville, Boston, Portland, and New York City. The Boggs School is
            implementing PBE in Detroit. PBE immerses students in local
            heritage, cultures, landscapes, opportunities, and experiences,
            using these as a foundation for the study of language arts,
            mathematics, social studies, science, and other subjects across the
            curriculum. PBE emphasizes learning through participation in service
            projects for the school and local community, often focused on
            eco-justice.
          </p>
        </div>
      </section>
      <section
        className={`${styles.learning_section} ${styles.section_half_circle}`}
      >
        <h1>Learning...</h1>
        <ul className={styles.p_b_e_learning_list}>
          <li>
            <p>
              ...takes place on-site, in the schoolyard, and in the local
              community and environment.
            </p>
          </li>
          <li>
            <p>...focuses on local themes, systems, and content.</p>
          </li>
          <li>
            <p>
              ...serves as the foundation for understanding and participating in
              regional and global issues.
            </p>
          </li>
          <li>
            <p>...is personally relevant to each learner.</p>
          </li>
          <li>
            <p>
              ...is grounded in and supports the development of a love for one’s
              place.
            </p>
          </li>
          <li>
            <p>...is interdisciplinary.</p>
          </li>
          <li>
            <p>
              ...is supported by strong and varied partnerships with local
              residents, organizations, businesses, and government.
            </p>
          </li>
          <li>
            <p>...experiences are tailored to the local audience.</p>
          </li>
          <li>
            <p>
              ...experiences contribute to the community’s vitality and
              environmental quality and support the community’s role in
              fostering global environmental quality.
            </p>
          </li>
        </ul>
      </section>
    </div>
  )
}

export default PlaceBasedEducation

PlaceBasedEducation.propTypes = {
  visible: PropTypes.bool.isRequired,
}
