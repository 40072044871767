import React, { useState } from "react"
import DocumentLink from "../../document-link"
import * as styles from "./transparency.module.scss"
import PropTypes from "prop-types"
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"

function TransparencyLinkList({ links }) {
  const {
    title,
    link1,
    text1,
    link2,
    text2,
    link3,
    text3,
    link4,
    text4,
    no_links_text,
  } = links

  const [expanded, setExpanded] = useState(false)

  const hasLinks = () => {
    return link1.url && text1.text
  }

  const linkList = [link1, link2, link3, link4]
  const textList = [text1, text2, text3, text4]

  if (expanded) {
    if (no_links_text.text) {
      return (
        <div>
          <button
            className={styles.links_title_and_icon}
            onClick={() => setExpanded(!expanded)}
          >
            <h2>{title.text}</h2>
            <div className={styles.icon}>
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </button>
          <p>{no_links_text.text}</p>
        </div>
      )
    } else if (hasLinks()) {
      return (
        <div>
          <button
            className={styles.links_title_and_icon}
            onClick={() => setExpanded(!expanded)}
          >
            <h2>{title.text}</h2>
            <div className={styles.icon}>
              <FontAwesomeIcon
                icon={faChevronDown}
                onClick={() => setExpanded(!expanded)}
              />
            </div>
          </button>
          <ul>
            {linkList.map((obj, index) => (
              <DocumentLink
                key={index}
                link={obj.url}
                text={textList[index].text}
              />
            ))}
          </ul>
        </div>
      )
    }
  } else {
    if (hasLinks() || no_links_text.text) {
      return (
        <button
          className={styles.links_title_and_icon}
          onClick={() => setExpanded(!expanded)}
        >
          <h2>{title.text}</h2>
          <div className={styles.icon}>
            <FontAwesomeIcon
              icon={faChevronRight}
              onClick={() => setExpanded(!expanded)}
            />
          </div>
        </button>
      )
    } else {
      return <></>
    }
  }
}

TransparencyLinkList.propTypes = {
  links: PropTypes.object.isRequired,
}

export default TransparencyLinkList
