import React from "react"
import * as styles from "./partners.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { useStaticQuery, graphql } from "gatsby"

function AFTMichigan(props) {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              aft_hero_image {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div>
      <h2 id={props.id}>American Federation of Teachers - Michigan</h2>
      <div
        className={`${styles.image_and_text_container} ${styles.right_image}`}
      >
        <div className={styles.text_container}>
          <p>
            In 2014, our teachers formed a collective bargaining unit under
            Michigan ACTS/AFT/AFL-CIO, making them one of the first charter
            schools in Michigan to unionize.
          </p>
          <p>In 2016, our support staff joined the CBU.</p>
        </div>
        <div className={styles.image_container}>
          <GatsbyImage
            className={styles.image_container_image}
            alt={data.aft_hero_image.alt}
            image={data.aft_hero_image.gatsbyImageData}
          />
        </div>
      </div>
    </div>
  )
}

AFTMichigan.propTypes = {
  id: PropTypes.string.isRequired,
}

export default AFTMichigan
