import { graphql, useStaticQuery } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import React from "react"
import ReactMarkdown from "react-markdown"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import * as styles from "./origins.module.scss"

function Origins(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsOrigins {
        edges {
          node {
            data {
              image1 {
                alt
                gatsbyImageData
              }
              image2 {
                alt
                gatsbyImageData
              }
              image3 {
                alt
                gatsbyImageData
              }
              text1 {
                text
              }
              text2 {
                text
              }
              text3 {
                text
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsOrigins.edges[0].node.data
  return (
    <div className={`${styles.origins} ${containerAnimation}`}>
      <h1>Origins</h1>
      <div
        className={`${styles.image_1_container} ${styles.general_image_container}`}
      >
        <ReactMarkdown key={0} source={data.text1.text} />
        <GatsbyImage
          className={styles.image}
          alt={data.image1.alt}
          image={data.image1.gatsbyImageData}
        />
      </div>
      <ReactMarkdown key={1} source={data.text2.text} />
      <div
        className={`${styles.image_2_container} ${styles.general_image_container}`}
      >
        <GatsbyImage
          className={styles.image}
          alt={data.image2.alt}
          image={data.image2.gatsbyImageData}
        />
      </div>
      <ReactMarkdown key={1} source={data.text3.text} />
      <div
        className={`${styles.image_3_container} ${styles.general_image_container}`}
      >
        <GatsbyImage
          alt={data.image3.alt}
          image={data.image3.gatsbyImageData}
        />
      </div>
    </div>
  )
}

export default Origins
