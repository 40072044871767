import React, { useState } from "react"
import * as styles from "./board-of-directors.module.scss"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import {
  faChevronRight,
  faChevronDown,
} from "@fortawesome/free-solid-svg-icons"
import DocumentLink from "../../document-link"
import { useStaticQuery, graphql } from "gatsby"

function OfficialDocuments() {
  const [expanded, setExpanded] = useState(false)

  const query = useStaticQuery(graphql`
    query {
      allPrismicBoggsOfficialDocuments(
        sort: { order: ASC, fields: data___item___text }
      ) {
        edges {
          node {
            data {
              item {
                link {
                  url
                }
                text
              }
            }
          }
        }
      }
    }
  `)

  if (expanded) {
    return (
      <div className={styles.official_documents_expanded}>
        <div className={styles.header_and_icon}>
          <button onClick={() => setExpanded(!expanded)}>
            <h2>Official Documents</h2>
          </button>
          <button
            onClick={() => setExpanded(!expanded)}
            className={styles.icon}
          >
            <FontAwesomeIcon icon={faChevronDown} />
          </button>
        </div>
        <ul className={styles.links}>
          {query.allPrismicBoggsOfficialDocuments.edges[0].node.data.item.map(
            (obj, index) => {
              return (
                <DocumentLink key={index} link={obj.link.url} text={obj.text} />
              )
            }
          )}
        </ul>
      </div>
    )
  } else {
    return (
      <div className={styles.official_documents_closed}>
        <button onClick={() => setExpanded(!expanded)}>
          <h2>Official Documents</h2>
        </button>
        <button onClick={() => setExpanded(!expanded)} className={styles.icon}>
          <FontAwesomeIcon
            title={"Open official documents chevron"}
            className={styles.icon}
            icon={faChevronRight}
          />
        </button>
      </div>
    )
  }
}

export default OfficialDocuments
