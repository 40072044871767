import React from "react"
import * as styles from "./partners.module.scss"

function MentorSchools(props) {
  return (
    <div>
      <h2 id={props.id}>Mentor Schools</h2>
      <div
        className={`${styles.image_and_text_container} ${styles.mentor_schools_special_case}`}
      >
        <div className={styles.text_container}>
          <div className={styles.two_columns}>
            <ul>
              <li>Nataki Talibah Schoolhouse, Detroit, MI</li>
              <li>
                <a target="_blank" rel="noreferrer" href="http://www.missionhillschool.org/">
                  <p aria-role="link" tabindex={0} className="inline">Mission Hill School, Roxbury, MA</p>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://www.theprojectschool.org/">
                  <p aria-role="link" tabindex={0} className="inline">The Project School, Bloomington, IN</p>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="https://www.pps.net/sunnyside">
                  <p aria-role="link" tabindex={0} className="inline">Sunnyside Environmental School, Portland, OR</p>
                </a>
              </li>
              <li>The New City School, Long Beach, CA</li>
              <li>
                <a target="_blank" rel="noreferrer" href="http://www.youngachieversschool.org/">
                  <p aria-role="link" tabindex={0} className="inline">Young Achievers, Boston, MA</p>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="http://www.fgcschool.org/">
                  <p aria-role="link" tabindex={0} className="inline">Forest Grove Community School, Forest Grove, OR</p>
                </a>
              </li>
            </ul>
            <ul>
              <li>
                <a target="_blank" rel="noreferrer" href="http://www.detroitwaldorf.com/">
                  <p aria-role="link" tabindex={0} className="inline">The Detroit Waldorf School, Detroit, MI</p>
                </a>
              </li>
              <li>AuSable Primary School, AuSable, MI</li>
              <li>
                <a target="_blank" rel="noreferrer" href="http://honeycreekschool.org/">
                  <p aria-role="link" tabindex={0} className="inline">Honey Creek Community School, Ann Arbor, MI</p>
                </a>
              </li>
              <li>
                <a target="_blank" rel="noreferrer" href="http://www.thelearningcommunity.com/site/">
                  <p aria-role="link" tabindex={0} className="inline">The Learning Community, Central Falls, RI</p>
                </a>
              </li>
              <li>Blossom Home Preschool, Ann Arbor, MI</li>
              <li>
                <a target="_blank" rel="noreferrer" href="http://www.nuestraescuela.org/">
                  <p aria-role="link" tabindex={0} className="inline">Nuestra Escuela, Caguas, Puerto Rico</p>
                </a>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  )
}

export default MentorSchools
