import React from "react"
import * as styles from "./grace-and-jimmy.module.scss"
import { useStaticQuery, graphql, PageRenderer } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import { getContentSwapAnimation } from "../../content-swapper-utils"
import parser from "html-react-parser"


function GraceAndJimmy(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)

  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              grace_and_jimmy_hero {
                alt
                gatsbyImageData
              }
              grace {
                alt
                gatsbyImageData
              }
              jimmy {
                alt
                gatsbyImageData
              }
              grace_and_jimmy_outro {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
      allPrismicBoggsGraceAndJimmyText {
        edges {
          node {
            data {
              grace_text {
                html
              }
              jimmy_text {
                html
              }
            }
          }
        }
      }
    }
  `)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data
  const textData = query.allPrismicBoggsGraceAndJimmyText.edges[0].node.data

  return (
    <div className={`${containerAnimation} ${styles.grace_and_jimmy}`}>
      <section>
        <h1>Grace &amp; Jimmy</h1>
        <div className={`${styles.image} ${styles.intro_image}`}>
          <GatsbyImage
            alt={data.grace_and_jimmy_hero.alt}
            image={data.grace_and_jimmy_hero.gatsbyImageData}
          />
        </div>
      </section>
      <section>
        <div className={styles.bio_container}>
          <div className={styles.head_shot_container}>
            <GatsbyImage
              className={styles.head_shot}
              alt={data.grace.alt}
              image={data.grace.gatsbyImageData}
            />
          </div>
          <div>
            <h2>Grace Lee Boggs (1915-2015)</h2>
            {parser(textData.grace_text.html)}
          </div>
        </div>
      </section>
      <hr />
      <section>
        <div className={styles.bio_container}>
          <div
            className={`${styles.head_shot_container} ${styles.jimmy_image}`}
          >
            <GatsbyImage
              className={styles.head_shot}
              alt={data.jimmy.alt}
              image={data.jimmy.gatsbyImageData}
            />
          </div>
          <div className={styles.jimmy_bio}>
            <h2>James Boggs (1919-1993)</h2>
            {parser(textData.jimmy_text.html)}
          </div>
        </div>
      </section>
      <section className={styles.section_outro}>
        <div className={`${styles.image} ${styles.outro_image}`}>
          <GatsbyImage
            alt={data.grace_and_jimmy_outro.alt}
            image={data.grace_and_jimmy_outro.gatsbyImageData}
          />
        </div>
      </section>
    </div>
  )
}

export default GraceAndJimmy
