import React from "react"
import * as styles from "./partners.module.scss"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"

function ImageLinkOut(props) {
  const altText = props.altText === undefined ? "Placeholder alt text" : props.altText
  return (
    <a target="_blank" rel="noreferrer" href={props.link}>
      <div tabIndex={0} className={styles.image_link_out}>
        <GatsbyImage
          className={styles.image_link_out_image}
          alt={altText}
          image={props.image}
        />
        <h3>{props.text}</h3>
      </div>
    </a>
  )
}

ImageLinkOut.propTypes = {
  fixed: PropTypes.object.isRequired,
  alt: PropTypes.string.isRequired,
  link: PropTypes.string.isRequired,
  text: PropTypes.string.isRequired,
}

export default ImageLinkOut
