// extracted by mini-css-extract-plugin
export var container_animation = "place-based-education-module--container_animation--P1Z4J";
export var container_animation_hide = "place-based-education-module--container_animation_hide--DrG6+";
export var container_animation_show = "place-based-education-module--container_animation_show--yF3xv";
export var content_swapper_container = "place-based-education-module--content_swapper_container--rP6nf";
export var content_swapper_container_show = "place-based-education-module--content_swapper_container_show--lquc6";
export var drop_down_hide = "place-based-education-module--drop_down_hide--CH5Eb";
export var drop_down_show = "place-based-education-module--drop_down_show--KlGl3";
export var learning_section = "place-based-education-module--learning_section--7fV-6";
export var p_b_e_copy_1 = "place-based-education-module--p_b_e_copy_1--tQCFy";
export var p_b_e_copy_2 = "place-based-education-module--p_b_e_copy_2--zxwLr";
export var p_b_e_hero_image = "place-based-education-module--p_b_e_hero_image--ybXWf";
export var p_b_e_hero_image_container = "place-based-education-module--p_b_e_hero_image_container--DniHV";
export var p_b_e_learning_list = "place-based-education-module--p_b_e_learning_list--yctcr";
export var place_based_education = "place-based-education-module--place_based_education--2S950";
export var section_bottom_triangle = "place-based-education-module--section_bottom_triangle--rySEa";
export var section_boxes = "place-based-education-module--section_boxes--5EAoO";
export var section_half_circle = "place-based-education-module--section_half_circle--ld9bg";