import React from "react"
import PropTypes from "prop-types"
import * as styles from "./board-of-directors.module.scss"

function Agenda(props) {
  if (props.agenda) {
    return (
      <div className={styles.agenda_and_minutes_container}>
        <h3>Agenda</h3>
        <a href={props.agenda} rel="noreferrer" target="_blank"><p tabIndex={0}>Proposed</p></a>
      </div>
    )
  } else {
    return <></>
  }
}

Agenda.propTypes = {
  agenda: PropTypes.string,
}

export default Agenda
