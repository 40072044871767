import React from "react"
import MissionAndCoreIdeology from "./mission-and-core-ideology"
import PropTypes from "prop-types"
import {
  navItems
} from "./about-us-nav-bar"
import PlaceBasedEducation from "./place-based-education"
import BoardOfDirectors from "./board-of-directors"
import GraceAndJimmy from "./grace-and-jimmy"
import Transparency from "./transparency"
import Partners from "./partners"
import ContactUs from "./contact-us"
import Origins from "./origins"

function AboutUsContentSwapper(props) {
  switch (props.current) {
    case navItems.missionAndCoreIdeology.name:
      return <MissionAndCoreIdeology visible={props.visible} />
    case navItems.placeBasedEducation.name:
      return <PlaceBasedEducation visible={props.visible} />
    case navItems.boardOfDirectors.name:
      return <BoardOfDirectors visible={props.visible} />
    case navItems.origins.name:
      return <Origins visible={props.visible} />
    case navItems.graceAndJimmy.name:
      return <GraceAndJimmy visible={props.visible} />
    case navItems.transparency.name:
      return <Transparency visible={props.visible} />
    case navItems.partners.name:
      return <Partners visible={props.visible} />
    case navItems.contactUs.name:
      return <ContactUs visible={props.visible} />
    default:
      return <></>
  }
}

export default AboutUsContentSwapper

AboutUsContentSwapper.propTypes = {
  current: PropTypes.string.isRequired,
  visible: PropTypes.bool.isRequired,
}
