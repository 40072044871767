// extracted by mini-css-extract-plugin
export var container_animation = "mission-and-core-ideology-module--container_animation--cLRAQ";
export var container_animation_hide = "mission-and-core-ideology-module--container_animation_hide--DMhw2";
export var container_animation_show = "mission-and-core-ideology-module--container_animation_show--nJzl1";
export var content_swapper_container = "mission-and-core-ideology-module--content_swapper_container--7WapR";
export var content_swapper_container_show = "mission-and-core-ideology-module--content_swapper_container_show--pDDVR";
export var core_purposes = "mission-and-core-ideology-module--core_purposes--pwxoZ";
export var drop_down_hide = "mission-and-core-ideology-module--drop_down_hide--E4MuF";
export var drop_down_show = "mission-and-core-ideology-module--drop_down_show--cX8V0";
export var h_o_h_a_m = "mission-and-core-ideology-module--h_o_h_a_m---T1IW";
export var ideology = "mission-and-core-ideology-module--ideology--nkq5v";
export var ideology_grid = "mission-and-core-ideology-module--ideology_grid--b+ANP";
export var ideology_items = "mission-and-core-ideology-module--ideology_items--fFANP";
export var ideology_vertical_divider = "mission-and-core-ideology-module--ideology_vertical_divider--sPJoQ";
export var m_a_c_content = "mission-and-core-ideology-module--m_a_c_content--gGtTe";
export var m_a_c_hero_image = "mission-and-core-ideology-module--m_a_c_hero_image--QV4aw";
export var m_a_c_hero_image_container = "mission-and-core-ideology-module--m_a_c_hero_image_container--o2iba";
export var mission = "mission-and-core-ideology-module--mission--Br+6u";
export var mission_and_core_ideology = "mission-and-core-ideology-module--mission_and_core_ideology--DO3VH";
export var mission_boxes_padding = "mission-and-core-ideology-module--mission_boxes_padding--hPF4O";
export var section_bottom_triangle = "mission-and-core-ideology-module--section_bottom_triangle--NC9x2";
export var section_boxes = "mission-and-core-ideology-module--section_boxes--BqERr";
export var section_half_circle = "mission-and-core-ideology-module--section_half_circle--yKqLM";