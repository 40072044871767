import React from "react"
import * as styles from "./mission-and-core-ideology.module.scss"
import { useStaticQuery, graphql } from "gatsby"
import { GatsbyImage } from "gatsby-plugin-image"
import PropTypes from "prop-types"
import { getContentSwapAnimation } from "../../content-swapper-utils"

function MissionAndCoreIdeology(props) {
  const query = useStaticQuery(graphql`
    {
      allPrismicBoggsImageBucket {
        edges {
          node {
            data {
              mission_and_core_ideology_hero {
                alt
                gatsbyImageData
              }
            }
          }
        }
      }
    }
  `)

  let containerAnimation = getContentSwapAnimation(props.visible)

  const data = query.allPrismicBoggsImageBucket.edges[0].node.data

  return (
    <div
      className={`${containerAnimation} ${styles.mission_and_core_ideology}`}
    >
      <section>
        <div
          className={`${styles.m_a_c_content} ${styles.mission_boxes_padding}`}
        >
          <div className={styles.mission}>
            <h1>Mission</h1>
            <p>
              The mission of the Boggs School is to nurture creative, critical
              thinkers who contribute to the well-being of their communities.
            </p>
          </div>
          <div className={styles.m_a_c_hero_image_container}>
            <GatsbyImage
              className={styles.m_a_c_hero_image}
              alt={data.mission_and_core_ideology_hero.alt}
              image={data.mission_and_core_ideology_hero.gatsbyImageData}
            />
          </div>
        </div>
      </section>
      <section className={styles.section_boxes}>
        <div className={styles.ideology}>
          <h1>Core Ideology</h1>
          <div className={styles.ideology_grid}>
            <div className={styles.ideology_items}>
              <h2>Core Values</h2>
              <div className={styles.ideology_vertical_divider} />
              <div>
                <p>High levels of critical thought, creativity, and learning</p>
                <p>Excellence in teaching</p>
                <p>Authentic, trusting relationships</p>
                <p>Community empowerment</p>
                <p>
                  Equity within both human relationships and the natural world
                </p>
              </div>
            </div>
            <div className={styles.ideology_items}>
              <h2>Core Purposes</h2>
              <div className={styles.ideology_vertical_divider} />
              <div className={styles.core_purposes}>
                <p>
                  To provide the tools to achieve ambitious goals and live lives
                  of meaning
                </p>
                <p>
                  To nurture a sense of place and develop a commitment to a
                  better Detroit
                </p>
                <p>
                  To grow our souls by developing a connection with ourselves,
                  each other, and the earth
                </p>
              </div>
            </div>
          </div>
        </div>
      </section>
      <section className={styles.h_o_h_a_m}>
        <h1>Habits of Mind, Heart &amp; Hands</h1>
        <ul>
          <li>
            <div>
              <h2>Empathy</h2>
              <p>The ability to understand and share the feelings of others</p>
            </div>
          </li>
          <li>
            <div>
              <h2>Inclusion</h2>
              <p>
                The practice of interacting with others in work and play, no
                matter who they are or where they come from
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2>Collaboration</h2>
              <p>
                Working with others to complete a task and to achieve shared
                goals
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2>Creativity</h2>
              <p>The ability to make new things or think of new ideas</p>
            </div>
          </li>
          <li>
            <div>
              <h2>Health</h2>
              <p>
                The practice of making choices that promote mental, emotional,
                and physical well-being
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2>Learning</h2>
              <p>Acquiring wisdom, knowledge, or skill</p>
            </div>
          </li>
          <li>
            <div>
              <h2>Self-Determination</h2>
              <p>
                The practice of having a goal-oriented and self-directing
                mindset
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2>Courage</h2>
              <p>
                Facing difficulties with persistence and bravery; addressing
                wrongs/mistakes with integrity and accountability
              </p>
            </div>
          </li>
          <li>
            <div>
              <h2>Critical Thinking</h2>
              <p>
                {" "}
                Disciplined thinking that is clear, rational, open-minded, and
                informed by evidence
              </p>
            </div>
          </li>
        </ul>
      </section>
    </div>
  )
}

export default MissionAndCoreIdeology

MissionAndCoreIdeology.propTypes = {
  visible: PropTypes.bool.isRequired,
}
