// extracted by mini-css-extract-plugin
export var agenda_and_minutes_container = "board-of-directors-module--agenda_and_minutes_container--3eEcF";
export var board_meeting_item = "board-of-directors-module--board_meeting_item--ypc5K";
export var board_meeting_item_docs_container = "board-of-directors-module--board_meeting_item_docs_container--ulb5+";
export var board_meetings = "board-of-directors-module--board_meetings--BnfOp";
export var board_of_directors = "board-of-directors-module--board_of_directors--MxoLo";
export var board_section = "board-of-directors-module--board_section--6TAtY";
export var container_animation = "board-of-directors-module--container_animation--uLmCQ";
export var container_animation_hide = "board-of-directors-module--container_animation_hide--NSAwc";
export var container_animation_show = "board-of-directors-module--container_animation_show--h2QQm";
export var content_swapper_container = "board-of-directors-module--content_swapper_container--bz5iB";
export var content_swapper_container_show = "board-of-directors-module--content_swapper_container_show--vbwbc";
export var disclaimer_items = "board-of-directors-module--disclaimer_items--0VvvU";
export var docs_container = "board-of-directors-module--docs_container--gVa8a";
export var docs_container_all = "board-of-directors-module--docs_container_all--1FVDS";
export var drop_down_hide = "board-of-directors-module--drop_down_hide--Hjhce";
export var drop_down_show = "board-of-directors-module--drop_down_show--wMOjV";
export var header_and_icon = "board-of-directors-module--header_and_icon--oQ-vp";
export var icon = "board-of-directors-module--icon--pEkZ9";
export var inline = "board-of-directors-module--inline--qA1c7";
export var intro_section = "board-of-directors-module--intro_section--VoTIf";
export var links = "board-of-directors-module--links--hlj4I";
export var minutes_both_subcontainer = "board-of-directors-module--minutes_both_subcontainer--9nbJa";
export var official_documents_closed = "board-of-directors-module--official_documents_closed--MSupY";
export var official_documents_expanded = "board-of-directors-module--official_documents_expanded--C2SSC";
export var outro_section = "board-of-directors-module--outro_section--Bu1iO";
export var section_bottom_triangle = "board-of-directors-module--section_bottom_triangle--DBNEY";
export var section_boxes = "board-of-directors-module--section_boxes--w-epF";
export var section_half_circle = "board-of-directors-module--section_half_circle--CBsgO";