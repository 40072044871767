import React from "react"
import * as styles from "./contact-us.module.scss"
import {
  faEnvelopeOpenText,
  faMapMarkerAlt,
} from "@fortawesome/free-solid-svg-icons"
import { faFacebook, faTwitter } from "@fortawesome/free-brands-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { getContentSwapAnimation } from "../../content-swapper-utils"

function ContactUs(props) {
  let containerAnimation = getContentSwapAnimation(props.visible)
  return (
    <div className={`${containerAnimation} ${styles.contact_us}`}>
      <section>
        <div className={styles.contact_text_container}>
          <h1>Contact Us</h1>
          <div className={styles.contact_grid}>
            <div>
              <h2>Phone Number</h2>
              <p>(313) 923-2301</p>
            </div>
            <div>
              <h2>Fax Number</h2>
              <p>(313) 923-2300</p>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="mailto:boggsschool@boggsschool.org">
                <FontAwesomeIcon icon={faEnvelopeOpenText} />
                <h2 tabIndex={0}>Contact</h2>
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://goo.gl/maps/i6c5msxp5yPzBhjd7">
                <FontAwesomeIcon icon={faMapMarkerAlt} />
                <h2 tabIndex={0}>Visit</h2>
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="https://twitter.com/BoggsSchool">
                <FontAwesomeIcon icon={faTwitter} />
                <h2 tabIndex={0}>Twitter</h2>
              </a>
            </div>
            <div>
              <a target="_blank" rel="noreferrer" href="http://www.facebook.com/pages/The-Boggs-Educational-Center/244370322273">
                <FontAwesomeIcon icon={faFacebook} />
                <h2 tabIndex={0}>Facebook</h2>
              </a>
            </div>
          </div>
        </div>
      </section>
    </div>
  )
}

export default ContactUs
